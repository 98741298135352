import {
  memo,
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  Fragment
} from 'react';
import { NumericFormat, PatternFormat } from 'react-number-format';
import styles from '../styles/modules/Input.module.scss';

const Input = forwardRef((props, ref) => {
  const {
    title,
    prefix,
    suffix,
    inputs,
    visibility,
    isWrappedStyle,
    style,
    onChange,
    onBlur,
    onFocus,
    disabled
  } = props;
  const [isError, setIsError] = useState(false);
  const [values, setValues] = useState(
    inputs.map((value) => (value && value.value ? value.value.toString() : ''))
  );
  const parentId = useRef();
  const inputId = useRef();

  const handleOnClick = () => {
    if (inputId.current && isWrappedStyle) {
      inputId.current.focus();
    }
  };
  const getInputWithMask = (m, d, i) => {
    if (m === 'number' || m === 'money')
      return (
        <NumericFormat
          thousandSeparator=" "
          value={values[i]}
          placeholder={d.placeholder}
          allowNegative={false}
          isAllowed={({ formattedValue, floatValue }) =>
            formattedValue === '' || floatValue <= (d.max || 9999999999)
          }
          type={d.type}
          onChange={(e) => {
            const newValues = [...values];
            newValues[i] = Number(e.target.value.replace(/[^0-9.]/g, ''))
              ? e.target.value.replace(/[^0-9.]/g, '')
              : '';

            setValues(newValues);
            setIsError(false);
            if (onChange) onChange(newValues);
          }}
          onFocus={() => {
            if (onFocus) onFocus(values);
          }}
          onBlur={() => {
            if (onBlur) onBlur(values);
          }}
          style={d.style}
          getInputRef={inputId}
          disabled={disabled}
        />
      );

    if (m === 'phone')
      return (
        <PatternFormat
          value={values[i]}
          className={styles.inputZone}
          format="+# ### ### ## ##"
          type={d.type}
          placeholder={d.placeholder}
          onChange={(e) => {
            const newValues = [...values];
            newValues[i] = e.target.value.replace(/[^0-9.]/g, '');

            setValues(newValues);
            setIsError(false);
            if (onChange) onChange(newValues);
          }}
          onFocus={() => {
            if (onFocus) onFocus(values);
          }}
          onBlur={() => {
            if (onBlur) onBlur(values);
          }}
          style={d.style}
          getInputRef={inputId}
          disabled={disabled}
        />
      );

    return null;
  };

  useEffect(() => {
    setValues(inputs.map((value) => (value && value.value ? value.value.toString() : '')));
  }, [inputs]);
  useImperativeHandle(ref, () => ({
    setError(s) {
      if (!s) {
        setIsError(false);
        return;
      }

      parentId.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
      parentId.current.animate(
        [
          { transform: 'translate3d(-2px, 0, 0)' },
          { transform: 'translate3d(2px, 0, 0)' },
          { transform: 'translate3d(-5px, 0, 0)' },
          { transform: 'translate3d(5px, 0, 0)' },
          { transform: 'translate3d(0, 0, 0)' }
        ],
        200
      );

      if (isError) return;

      setIsError(true);
      setTimeout(() => setIsError(false), 5000);
    }
  }));

  if (!inputs.length || visibility === false) return null;

  return (
    <div
      className={[
        styles.in,
        isWrappedStyle && styles.full,
        isError && styles.error,
        disabled && styles.disabled
      ]
        .filter(Boolean)
        .join(' ')}
      style={style}
      onClick={handleOnClick}
      ref={parentId}
    >
      {title && <div className={styles.inTitle}>{title}</div>}
      <div className={styles.inContent}>
        {prefix && <div className={styles.inPrefix}>{prefix}</div>}
        {inputs.map((value, index) => (
          <Fragment key={index}>
            {index > 0 && <div className={styles.inDivider} />}
            {value.mask ? (
              getInputWithMask(value.mask, value, index)
            ) : (
              <input
                key={index}
                placeholder={value.placeholder}
                value={values[index]}
                data-index={index}
                type={value.type}
                onChange={(e) => {
                  const newValues = [...values];
                  newValues[index] = e.target.value || '';

                  setValues(newValues);
                  setIsError(false);
                  if (onChange) onChange(newValues);
                }}
                onFocus={() => {
                  if (onFocus) onFocus(values);
                }}
                onBlur={() => {
                  if (onBlur) onBlur(values);
                }}
                style={value.style}
                ref={inputId}
                disabled={disabled}
              />
            )}
          </Fragment>
        ))}
        {suffix && <div className={styles.inSuffix}>{suffix}</div>}
      </div>
    </div>
  );
});

Input.displayName = 'Input';
Input.defaultProps = {
  inputs: [],
  visibility: true
};

export default memo(Input, (p, n) => {
  return JSON.stringify(p.inputs) === JSON.stringify(n.inputs) && p.visibility === n.visibility;
});
