import Link from 'next/link';
import { withRouter } from 'next/router';
import { Fragment } from 'react';
import { useAppState } from '../context/app';
import { useObjectsDispatch } from '../context/objects';
import navigationData from '../data/navigation.json';

const HeaderNavigation = ({ router }) => {
  const appState = useAppState();
  const setObjectsState = useObjectsDispatch();

  const onClickHandle = (path) => {
    switch (path) {
      case '/objects':
        setObjectsState({
          type: 'SET_FILTER',
          payload: {}
        });
        break;

      case '/objects/buy/flats':
        setObjectsState({
          type: 'SET_FILTER',
          payload: {
            type: 'продажа',
            category: 'квартира'
          }
        });
        break;

      case '/objects/buy/rooms':
        setObjectsState({
          type: 'SET_FILTER',
          payload: {
            type: 'продажа',
            category: 'комната'
          }
        });
        break;

      case '/objects/buy/houses':
        setObjectsState({
          type: 'SET_FILTER',
          payload: {
            type: 'продажа',
            category: 'дом'
          }
        });
        break;

      case '/objects/buy/commercial':
        setObjectsState({
          type: 'SET_FILTER',
          payload: {
            type: 'продажа',
            category: 'коммерция'
          }
        });
        break;

      default:
        break;
    }
  };

  return (
    <Fragment>
      <nav className="menu">
        {navigationData.map((value, index) => (
          <Fragment key={index}>
            <Link
              href={value.path}
              role="button"
              tabIndex={0}
              className={[
                'link',
                (router.pathname === value.path ||
                  (value.alias && value.alias.includes(router.pathname))) &&
                  'active'
              ]
                .filter(Boolean)
                .join(' ')}
              title={value.text}
              target={value.newTab && '_blank'}
              onClick={() => onClickHandle(value.path)}
            >
              {value.text}
              {value.label && <span className={value.labelColor}>{value.label}</span>}
            </Link>
          </Fragment>
        ))}
      </nav>
      <Link
        href="/favorites"
        passHref
        className="liked"
        title="Моё избранное"
        aria-label="Моё избранное"
      >
        {!!appState.favorites.length && <span>{appState.favorites.length}</span>}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path d="M20.16,5A6.29,6.29,0,0,0,12,4.36a6.27,6.27,0,0,0-8.16,9.48l6.21,6.22a2.78,2.78,0,0,0,3.9,0l6.21-6.22A6.27,6.27,0,0,0,20.16,5Zm-1.41,7.46-6.21,6.21a.76.76,0,0,1-1.08,0L5.25,12.43a4.29,4.29,0,0,1,0-6,4.27,4.27,0,0,1,6,0,1,1,0,0,0,1.42,0,4.27,4.27,0,0,1,6,0A4.29,4.29,0,0,1,18.75,12.43Z" />
        </svg>
      </Link>
    </Fragment>
  );
};

export default withRouter(HeaderNavigation);
