import { useState, useRef, Fragment } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Link from 'next/link';
import { useAppState } from '../context/app';
import { useLocationState } from '../context/location';
import navigationData from '../data/navigation.json';

const HeaderNavigationMobile = ({ onLocationClick }) => {
  const appState = useAppState();
  const locationState = useLocationState();
  const [isVisible, setVisibility] = useState(false);
  const parentEl = useRef(null);

  const onClickHandle = (path) => {
    setVisibility(false);

    switch (path) {
      case '/objects':
        setObjectsState({
          type: 'SET_FILTER',
          payload: {}
        });
        break;

      case '/objects/buy/flats':
        setObjectsState({
          type: 'SET_FILTER',
          payload: {
            type: 'продажа',
            category: 'квартира'
          }
        });
        break;

      case '/objects/buy/rooms':
        setObjectsState({
          type: 'SET_FILTER',
          payload: {
            type: 'продажа',
            category: 'комната'
          }
        });
        break;

      case '/objects/buy/houses':
        setObjectsState({
          type: 'SET_FILTER',
          payload: {
            type: 'продажа',
            category: 'дом'
          }
        });
        break;

      case '/objects/buy/commercial':
        setObjectsState({
          type: 'SET_FILTER',
          payload: {
            type: 'продажа',
            category: 'коммерция'
          }
        });
        break;

      default:
        break;
    }
  };

  return (
    <Fragment>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="menu mobile"
          >
            {navigationData.map((value, index) => {
              if (value.menu)
                return (
                  <button className="link column" key={index}>
                    {value.text}
                    {value.label && <span className={value.labelColor}>{value.label}</span>}
                    {value.menu && (
                      <div className="dropdown">
                        {value.menu.map((v, i) => (
                          <Link
                            href={v.path}
                            key={i}
                            passHref
                            className="link"
                            role="button"
                            tabIndex={0}
                            onClick={() => onClickHandle(v.path)}
                            title={v.text}
                          >
                            {v.text}
                            {v.label && <span className={v.labelColor}>{v.label}</span>}
                          </Link>
                        ))}
                      </div>
                    )}
                  </button>
                );

              return (
                <Link
                  href={value.path}
                  key={index}
                  passHref
                  className="link"
                  role="button"
                  tabIndex={0}
                  onClick={() => onClickHandle(value.path)}
                  title={value.text}
                >
                  {value.text}
                  {value.label && <span className={value.labelColor}>{value.label}</span>}
                </Link>
              );
            })}
            <a className="location" onClick={() => onLocationClick(true)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polygon points="3 11 22 2 13 21 11 13 3 11" />
              </svg>
              <span>{locationState.name}</span>
            </a>
            <a className="contact" href="tel:+78633019595">
              {locationState.phone}
              <div className="description">Доступны с 9:00 — 19:00 МСК</div>
            </a>
          </motion.div>
        )}
      </AnimatePresence>
      <div className="actions" ref={parentEl}>
        <Link
          href="/favorites"
          passHref
          className="liked"
          title="Моё избранное"
          aria-label="Моё избранное"
        >
          {!!appState.favorites.length && <span>{appState.favorites.length}</span>}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" />
          </svg>
        </Link>
        <button
          className={['button', isVisible ? 'active' : ''].join(' ')}
          type="button"
          aria-label="Открыть меню"
          onClick={() => setVisibility(!isVisible)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
          >
            <path
              className="line"
              xmlns="http://www.w3.org/2000/svg"
              d="M14 26C14 26 43.5 26 46 26C50 26 54 26 54 34C54 42 53.5417 44.5 49 44.5C45 44.5 44.7762 44.7762 42 42C39 39 18 18 18 18"
            />
            <path
              className="line"
              xmlns="http://www.w3.org/2000/svg"
              d="M14 34C14 34 43.5 34 46 34C50 34 54 34 54 26C54 18 53.5417 15.5 49 15.5C45 15.5 44.7762 15.2238 42 18C39 21 18 42 18 42"
            />
          </svg>
        </button>
      </div>
    </Fragment>
  );
};

export default HeaderNavigationMobile;
