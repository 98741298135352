import { useState, useRef } from 'react';
import styles from '../styles/modules/Textarea.module.scss';

const Textarea = ({ title, value, placeholder, isWrappedStyle, isError, autoHeight, onChange }) => {
  const [txt, setTxt] = useState(value);
  const parentId = useRef();
  const textareaId = useRef();

  const handleOnClick = () => {
    if (textareaId.current) {
      textareaId.current.focus();
    }
  };
  const handleOnChange = (e) => {
    if (textareaId.current && autoHeight) {
      textareaId.current.style.height = 'auto';
      textareaId.current.style.height = textareaId.current.scrollHeight + 'px';
    }

    if (onChange) onChange(e.target.value);

    setTxt(e.target.value);
  };

  return (
    <div
      className={[styles.txt, isWrappedStyle && styles.full, isError && styles.error]
        .filter(Boolean)
        .join(' ')}
      onClick={handleOnClick}
      ref={parentId}
    >
      {title && <div className={styles.txtTitle}>{title}</div>}
      <div className={styles.txtContent}>
        <textarea onInput={handleOnChange} ref={textareaId} placeholder={placeholder}>
          {txt}
        </textarea>
      </div>
    </div>
  );
};

export default Textarea;
