import dynamic from 'next/dynamic';
import { useState, useRef, Fragment } from 'react';
import Link from 'next/link';
import axios from 'axios';
import Input from './Input';
import Textarea from './Textarea';
import HeaderNavigation from './HeaderNavigation';
import HeaderNavigationMobile from './HeaderNavigationMobile';
import { useLocationProvider } from '../context/location';
import location from '../data/location.json';

const Modal = dynamic(() => import('./Modal'));

const Header = ({ children }) => {
  const [locationState, setLocationState] = useLocationProvider();
  const [isModalCallVisible, setIsModalCallVisible] = useState(false);
  const [isModalLocationVisible, setIsModalLocationVisible] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [clientComment, setClientComment] = useState('');
  const clientNameId = useRef();
  const clientPhoneId = useRef();

  const handleFormSubmit = () => {
    clientNameId.current.setError(clientName.length <= 3);
    clientPhoneId.current.setError(clientPhone.length !== 11);

    if (clientName.length > 3 && clientPhone.length === 11) {
      setIsSubmited(true);

      axios.post(process.env.NEXT_PUBLIC_API + '/client', {
        clientName,
        clientPhone: clientPhone,
        clientComment,
        pathName: window.location.pathname,
        whatComponent: 'Header',
        location: locationState.name,
        platform: 'web'
      });
      ym(21340345, 'reachGoal', 'all_form_submit');
    }
  };
  const handleLocationClick = (v) => {
    setIsModalLocationVisible(false);
    if (locationState.code === v.code) {
      return;
    }

    setLocationState({ type: 'SET_LOCATION', payload: { ...locationState, ...v } });
    localStorage.setItem('location', v.code);
  };

  return (
    <header>
      <Modal
        title="Заказать звонок"
        button={!isSubmited && 'Отправить'}
        action={handleFormSubmit}
        visibility={isModalCallVisible}
        onClose={() => setIsModalCallVisible(false)}
      >
        {isSubmited ? (
          <Fragment>
            <div style={{ marginBottom: 18 }}>Спасибо за заявку. Мы скоро с Вами свяжемся!</div>
            <img
              src="/images/thumbUp.png"
              alt=""
              style={{ padding: 50, width: '100%', boxSizing: 'border-box' }}
            />
          </Fragment>
        ) : (
          <Fragment>
            <div style={{ marginBottom: 18 }}>
              Оставьте свои контактные данные, и мы свяжемся с вами в ближайшее время, чтобы
              ответить на все ваши вопросы.
            </div>
            <Input
              title="Имя"
              inputs={[
                {
                  placeholder: 'Василий Петрович',
                  value: clientName,
                  type: 'text'
                }
              ]}
              isWrappedStyle
              ref={clientNameId}
              onChange={(v) => setClientName(v[0])}
            />
            <Input
              title="Телефон"
              inputs={[
                {
                  placeholder: locationState.phone,
                  value: clientPhone,
                  mask: 'phone',
                  type: 'tel'
                }
              ]}
              isWrappedStyle
              ref={clientPhoneId}
              onChange={(v) => setClientPhone(v[0])}
            />
            <Textarea
              title="Коммментарий"
              placeholder="Например, «Хочу получить консультацию перед продажей своей квартиры в городе»"
              autoHeight
              isWrappedStyle
              onChange={(v) => setClientComment(v)}
            />
            <div style={{ textAlign: 'center', opacity: 0.8, fontSize: 14, margin: '18px 0' }}>
              Вы соглашаетесь с{' '}
              <a href="/downloads/personal.pdf" target="_blank">
                политикой конфиденциальности
              </a>{' '}
              при отправке данной формы к нам в работу
            </div>
          </Fragment>
        )}
      </Modal>
      <Modal
        title="Выбор локации"
        visibility={isModalLocationVisible}
        onClose={() => setIsModalLocationVisible(false)}
      >
        <div style={{ marginBottom: 18 }}>
          Выберите локацию в которой будет осуществляться основной поиск объектов. К примеру,
          &quot;Ростов-на-Дону&quot; для поиска в городе и прилегающих территориях рядом.
        </div>
        <div style={{ margin: '18px -12px', borderBottom: '1px dashed rgba(0, 0, 0, 0.05)' }} />
        <div className="locations">
          {location.map((value, index) => (
            <button
              key={index}
              type="button"
              className={['location', locationState.name === value.name && 'active']
                .filter(Boolean)
                .join(' ')}
              onClick={() => handleLocationClick(value)}
            >
              {value.name}
              {locationState.name === value.name && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              )}
            </button>
          ))}
        </div>
      </Modal>
      <section className="preheader">
        <div className="inner">
          <button className="сity" type="button" onClick={() => setIsModalLocationVisible(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polygon points="3 11 22 2 13 21 11 13 3 11" />
            </svg>
            {locationState.name}
          </button>
          <button className="сall" type="button" onClick={() => setIsModalCallVisible(true)}>
            Заказать звонок
          </button>
        </div>
      </section>
      <section className="header">
        <Link href="/" className="logo">
          <svg
            viewBox="0 0 389 41"
            width="100%"
            height="100%"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 39.1495H12.8324L23.2736 11.1628H26.7009H31.9614V39.1495H45.272V0.877115L13.7091 0.797363L0 39.1495Z" />
            <path d="M50.2933 20.0134C50.2933 25.9935 50.1339 32.3722 49.8948 39.1496H90.9425V29.5018H62.4084V27.349V26.4719V24.0001C64.0821 24.0001 66.6327 23.9204 69.9006 23.9204C72.1323 23.9204 74.4437 23.9204 76.7551 24.0001H87.1964V15.4685H76.7551C74.4437 15.4685 72.1323 15.5483 69.9006 15.5483C66.553 15.5483 64.0821 15.5483 62.4084 15.4685V12.5981V10.4453H90.9425V0.877197H49.8948C50.1339 7.65461 50.2933 14.0333 50.2933 20.0134Z" />
            <path d="M127.846 39.1495H140.279C140.04 33.1695 139.881 26.7907 139.881 20.0133C139.881 13.1562 140.04 6.77743 140.279 0.797363H127.846V15.1495H108.398V0.797363H95.9639C96.2827 7.65451 96.3624 14.0332 96.3624 20.0133C96.3624 25.9934 96.203 32.3721 95.9639 39.1495H108.398V24.7974H127.846V39.1495Z" />
            <path d="M189.377 39.1495H192.326V28.8638H189.377V0.877115L157.814 0.797363L147.692 28.8638H144.025V39.1495H156.938H176.067H189.377ZM167.299 11.1628H170.806H176.067V28.9435H160.763L167.299 11.1628Z" />
            <path d="M228.193 39.4684H241.504V1.19593L209.941 1.11621L196.152 39.4684H209.064L219.426 11.4817H222.933H228.193V39.4684Z" />
            <path d="M269.48 40.0266C277.61 40.0266 283.588 38.2724 287.413 34.7641C291.159 31.2558 293.072 26.3123 293.072 19.9336C293.072 13.6346 291.159 8.77076 287.413 5.26246C283.667 1.75415 277.689 0 269.56 0C261.43 0 255.532 1.75415 251.706 5.26246C247.96 8.77076 246.047 13.7143 246.047 19.9336C246.047 26.3123 247.96 31.1761 251.786 34.6844C255.452 38.2724 261.43 40.0266 269.48 40.0266ZM261.031 13.0764C262.785 11.5615 265.574 10.8439 269.4 10.8439C273.306 10.8439 276.095 11.5615 277.849 13.0764C279.602 14.5914 280.399 16.9036 280.399 20.0133C280.399 23.2026 279.523 25.5149 277.849 27.0299C276.095 28.5448 273.306 29.2624 269.4 29.2624C265.495 29.2624 262.705 28.5448 261.031 27.0299C259.278 25.5149 258.481 23.2027 258.481 20.093C258.481 16.9037 259.357 14.5914 261.031 13.0764Z" />
            <path d="M335.873 4.94345C333.164 2.47169 328.222 1.19593 321.208 1.19593H310.209V1.11621H297.217C297.536 7.97335 297.615 14.3521 297.615 20.2524C297.615 26.2325 297.456 32.6112 297.217 39.4684H310.209V30.1395H321.208C326.07 30.1395 329.816 29.5016 332.526 28.3056C335.236 27.1096 337.228 25.4352 338.265 23.2026C339.38 21.0498 339.938 18.4185 339.938 15.3089C339.938 10.9235 338.583 7.41521 335.873 4.94345ZM327.186 18.4185C326.628 19.0564 325.592 19.4551 324.157 19.6943C322.722 19.9335 320.491 20.0132 317.621 20.0132H310.368V11.6411H317.621C320.65 11.6411 322.802 11.7209 324.316 11.9601C325.751 12.1993 326.787 12.5979 327.345 13.1561C327.903 13.7142 328.142 14.5913 328.142 15.7873C328.142 16.9833 327.744 17.7807 327.186 18.4185Z" />
            <path d="M385.529 29.2624V1.19593L353.966 1.11621L343.844 29.1827H340.177V39.4684H353.09H372.219H385.609H388.558V29.1827H385.529V29.2624ZM372.219 29.2624H356.915L363.531 11.4817H367.038H372.298V29.2624H372.219Z" />
          </svg>
        </Link>
        <HeaderNavigation />
        <HeaderNavigationMobile onLocationClick={() => setIsModalLocationVisible(true)} />
      </section>
      {children && <section className="aftheader">{children}</section>}
    </header>
  );
};

export default Header;
